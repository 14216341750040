<template>
  <v-container class="general">
    <div
      v-for="(section, sectionIndex) in dashboardSections"
      :key="`section-${sectionIndex}`"
      class="dashboard-section"
    >
      <page-title :title="$t(section.title)">
        <template slot="actions">
          <VideoTutorial
            :component="section.tutorial"
            :title="$t(section.title)"
          />
        </template>
      </page-title>
      <div v-if="section.hint" class="mb-5 px-4 sw-h5">
        {{ $t(section.hint) }}
      </div>
      <v-layout
        v-if="section.template === 'SingleRowSection'"
        class="row wrap mx-n4"
      >
        <v-flex
          v-for="(card, cardIndex) in section.cards"
          :key="`card-${cardIndex}`"
          :class="`px-4 ${card.class}`"
        >
          <v-card
            hover
            class="fill-height pa-4 text-center border-radius"
            @click="routeTo(card.route)"
          >
            <div v-if="card.icon" class="mb-1 display-3">
              <font-awesome-icon :icon="card.icon" class="sw-accent" />
            </div>
            <div class="sw-h5 font-weight-light">{{ $t(card.title) }}</div>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="section.template === 'StatisticsSection'"
        class="row wrap"
      >
        <v-flex class="xs11 pl-4 pt-4">
          <v-layout class="row wrap mx-n4">
            <v-flex class="xs6 mb-2 px-4">
              <UpcomingMessages :group-id="groupId" />
            </v-flex>
            <v-flex class="xs6 mb-2 px-4">
              <TotalMessagesSent :group-id="groupId" />
            </v-flex>
            <v-flex class="xs12 px-4 pt-5">
              <MemberStatistics :group-id="groupId" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import UpcomingMessages from "@/components/Dashboard/StatisticsSection/UpcomingMessages.vue";
import TotalMessagesSent from "@/components/Dashboard/StatisticsSection/TotalMessagesSent.vue";
import MemberStatistics from "@/components/Dashboard/StatisticsSection/MemberStatistics.vue";
import { sections } from "@/components/Dashboard/sections.json";

export default {
  data: () => ({
    groupPlugins: [],
  }),
  computed: {
    appName() {
      return process.env.VUE_APP_NAME;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    dashboardSections() {
      return sections;
    },
  },
  components: {
    UpcomingMessages,
    TotalMessagesSent,
    MemberStatistics,
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.listGroupPlugins();
      },
    },
  },
  methods: {
    async listGroupPlugins() {
      try {
        const params = [
          this.groupId,
          {
            with_attributes: 1,
            page: 1,
            per_page: 500,
          },
        ];

        const response = await this.$api.groupPlugins.list(...params);

        this.groupPlugins = response.data.data.map((el) => ({
          id: el.id,
          uid: el.attributes.uid,
        }));
      } catch (error) {
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
      }
    },
    routeTo(route) {
      const completeRoute = {
        name: route.name,
        params: {},
        query: route.query,
      };

      if (route.params && route.params.plugin_uid) {
        const groupPlugin = this.groupPlugins.find(
          (el) => el.uid === route.params.plugin_uid,
        );

        if (groupPlugin && groupPlugin.id) {
          completeRoute.params.group_plugin_id = groupPlugin.id;
        }
      }

      this.$router.push(completeRoute, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-section {
  margin: 0 0 210px 0;
}
</style>
