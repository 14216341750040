<template>
  <v-layout class="row wrap">
    <v-flex class="xs6 pr-4">
      <v-card flat height="48" class="d-flex align-center">
        <div class="sw-h5">
          {{ $t("dashboardMemberStatistics") }}
        </div>
      </v-card>
      <v-divider class="mt-2 mb-3 sw-secondary-bg"></v-divider>
      <v-layout class="row wrap">
        <v-flex class="xs6">
          <v-layout class="row wrap">
            <v-flex class="xs12">
              <div
                :style="{ lineHeight: '75px' }"
                class="sw-h2 sw-accent font-weight-light"
              >
                {{ groupUserCount }}
              </div>
            </v-flex>
            <v-flex class="xs12">
              <div class="sw-h6 font-weight-light">
                {{ $t("dashboardMembersInCommunity") }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="xs6">
          <v-layout class="row wrap">
            <v-flex class="xs12">
              <div
                :style="{ lineHeight: '75px' }"
                class="sw-h2 sw-accent font-weight-light"
              >
                {{ appUserCount }}
              </div>
            </v-flex>
            <v-flex class="xs12">
              <div class="sw-h6 font-weight-light">
                {{ $t("dashboardActiveMembers") }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="xs12 my-3">
          <v-divider class="sw-secondary-bg"></v-divider>
        </v-flex>
        <v-flex class="xs12">
          <v-layout class="row wrap">
            <v-flex class="xs12">
              <div
                :style="{ lineHeight: '75px' }"
                class="sw-h2 sw-accent font-weight-light"
              >
                {{ usersVisitedApp }}
              </div>
            </v-flex>
            <v-flex class="xs12">
              <div class="sw-h6 font-weight-light">
                {{
                  $t("dashboardMembersHaveVisitedYourApp", {
                    name: appName,
                  })
                }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex class="xs6">
      <Chart :chartData="chartdata" />
    </v-flex>
  </v-layout>
</template>

<script>
import Chart from "@/components/Charts/Chart.vue";
import variables from "@/assets/styles/variables.scss";

export default {
  components: {
    Chart,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    groupUserCount: null,
    appUserCount: null,
  }),
  computed: {
    usersVisitedApp() {
      const users = +Number(
        (100 / this.groupUserCount) * this.appUserCount,
      ).toFixed(2);
      return !isNaN(users) ? users + "%" : "0%";
    },
    chartdata() {
      return [
        {
          value: this.groupUserCount - this.appUserCount,
          name: this.$t("dashboardMembersInCommunity"),
          itemStyle: {
            color: variables.primaryBg,
          },
        },
        {
          value: this.appUserCount,
          name: this.$t("dashboardActiveMembers"),
          itemStyle: {
            color: variables.accent,
          },
        },
      ];
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
  },
  mounted() {
    this.syncStats();
    this.clearStatTimer();
    this.registerStatTimer();
  },
  methods: {
    getGroupUserCount() {
      const params = [
        this.groupId,
        {
          per_page: 1,
        },
      ];

      this.$api.groupUsers.list(...params).then((response) => {
        if (!response) return;

        this.groupUserCount = response.data.pagination.total;
      });
    },
    getAppUserCount() {
      const params = [
        this.groupId,
        {
          per_page: 1,
        },
      ];

      this.$api.groupAppUsers.count(...params).then((response) => {
        if (!response) return;

        this.appUserCount = response.data.data.count;
      });
    },
    syncStats() {
      this.getGroupUserCount();
      this.getAppUserCount();
    },
    registerStatTimer() {
      if (!this.statsInterval) {
        this.statsInterval = setInterval(this.syncStats, 15000);
      }
    },
    clearStatTimer() {
      clearInterval(this.statsInterval);
    },
  },
  beforeDestroy() {
    this.clearStatTimer();
  },
};
</script>

<style scoped lang="scss"></style>
