<template>
  <v-layout class="row wrap">
    <v-flex class="xs12 mb-4">
      <v-card flat height="48">
        <v-layout class="row wrap align-center">
          <v-flex class="xs6"
            ><div class="sw-h5">
              {{ $t("dashboardUpcomingMessages") }}
            </div>
          </v-flex>
          <v-flex v-if="groupPlugin && groupPlugin.id" class="xs6 text-right">
            <v-btn
              :to="{
                name: 'group_message_templates',
                params: { group_plugin_id: groupPlugin.id },
              }"
              round
              class="sw-secondary-bg sw-on-secondary text-none"
              >{{ $t("dashboardViewAllMessages") }}
            </v-btn>
          </v-flex>
          <v-flex class="xs12 my-2">
            <v-divider class="sw-secondary-bg"></v-divider>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex v-if="!loading && groupPlugin && groupPlugin.id" class="xs12">
      <div v-for="message in upcomingMessages" :key="message.id" class="mb-2">
        <div class="sw-caption grey--text">
          {{ $tc("type-" + message.type) }},
          {{ formatDate(message.send_time_start) }}
        </div>
        <a
          @click.prevent="showMessage(message.id, message.type)"
          class="sw-accent"
          >{{ message.name }}</a
        >
      </div></v-flex
    >
  </v-layout>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    perPage: 3,
    groupPlugin: null,
    upcomingMessages: [],
  }),
  mounted() {
    this.listGroupPlugins();
  },
  methods: {
    listGroupPlugins() {
      const params = [this.groupId, { prefix: "message" }];

      this.$api.groupPlugins.list(...params).then((response) => {
        if (!response.data.data) return;
        this.groupPlugin = response.data.data[0];

        this.syncStats();
        this.clearStatTimer();
        this.registerStatTimer();
      });
    },
    listMessages() {
      if (!this.groupPlugin) return;

      this.loading = true;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPlugin.id,
          per_page: this.perPage,
          sort: "send_time_start:desc",
          upcoming: 1,
          active: 1,
        },
      ];

      this.$api.groupMessageTemplates.list(...params).then((response) => {
        this.loading = false;

        if (!response) return;

        this.upcomingMessages = response.data.data;
      });
    },
    formatDate(date) {
      if (!date) return;
      return moment.tz(date, this.userTimezone).format("DD-MM-YYYY H:mm");
    },
    showMessage(id, type) {
      if (!this.groupPlugin || !id) {
        return;
      }

      this.$router
        .push({
          name: "group_message_templates_edit",
          params: {
            group_id: this.groupId,
            group_plugin_id: this.groupPlugin.id,
            message_id: id,
          },
          query: {
            type: type,
          },
        })
        .catch(() => {});
    },
    syncStats() {
      this.listMessages();
    },
    registerStatTimer() {
      if (!this.statsInterval) {
        this.statsInterval = setInterval(this.syncStats, 15000);
      }
    },
    clearStatTimer() {
      clearInterval(this.statsInterval);
    },
  },
  beforeDestroy() {
    this.clearStatTimer();
  },
};
</script>

<style scoped lang="scss"></style>
