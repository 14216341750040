<template>
  <v-layout class="row wrap">
    <v-flex class="xs12">
      <v-card flat height="48" class="d-flex align-center">
        <div class="sw-h5">
          {{ $t("dashboardMessages") }}
        </div></v-card
      >
    </v-flex>
    <v-flex class="xs12 mt-2 mb-3">
      <v-divider class="sw-secondary-bg"></v-divider>
    </v-flex>
    <v-flex class="xs6 mb-3">
      <span
        :style="{ lineHeight: '75px' }"
        class="sw-h2 font-weight-light sw-accent"
        >{{ count.sms }}</span
      >
      <div class="sw-h6 font-weight-light">
        {{ $t("dashboardSMSsSent") }}
      </div></v-flex
    >
    <v-flex class="xs6 mb-3"
      ><span
        :style="{ lineHeight: '75px' }"
        class="sw-h2 font-weight-light sw-accent"
        >{{ count.email }}</span
      >
      <div class="sw-h6 font-weight-light">
        {{ $t("dashboardEmailsSent") }}
      </div></v-flex
    >
    <v-flex class="xs12 mb-3">
      <v-divider class="sw-secondary-bg"></v-divider>
    </v-flex>
    <v-flex class="xs6"
      ><span
        :style="{ lineHeight: '75px' }"
        class="sw-h2 font-weight-light sw-accent"
        >{{ count.push }}</span
      >
      <div class="sw-h6 font-weight-light">
        {{ $t("dashboardPushNotificationsSent") }}
      </div></v-flex
    >
  </v-layout>
</template>

<script>
export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    messageTypes: ["email", "sms", "push"],
    count: {
      email: null,
      sms: null,
      push: null,
    },
    statsInterval: null,
  }),
  mounted() {
    this.syncStats();
    this.clearStatTimer();
    this.registerStatTimer();
  },
  methods: {
    async getMessageCount() {
      for await (const type of this.messageTypes) {
        const params = [this.groupId, { type: [type], per_page: 1 }];

        const response = await this.$api.groupUserMessages.list(...params);

        if (!response) return;

        this.$set(this.count, type, response.data.pagination.total);
      }
    },
    syncStats() {
      this.getMessageCount();
    },
    registerStatTimer() {
      if (!this.statsInterval) {
        this.statsInterval = setInterval(this.syncStats, 15000);
      }
    },
    clearStatTimer() {
      clearInterval(this.statsInterval);
    },
  },
  beforeDestroy() {
    this.clearStatTimer();
  },
};
</script>

<style scoped lang="scss"></style>
